/* Reset für einheitliches Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

/* Allgemeine Farben und Layout */
body {
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
}

/* Header-Design */
.header {
  background-color: #1f2937;
  color: #ffffff;
  padding: 2rem 1rem;
  text-align: center;
}

.header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.header p {
  font-size: 1.1rem;
  color: #9ca3af;
}

/* Navbar-Styling */
.navbar {
  background-color: #111827;
  padding: 1rem 0;
}

.navbar ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

.navbar li {
  margin: 0 1.5rem;
}

.navbar a {
  color: #e5e7eb;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #60a5fa;
}

/* Hauptinhalt */
main {
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.content h2 {
  color: #1f2937;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.content h3 {
  color: #374151;
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

.content p {
  color: #000000;
  margin-bottom: 1rem;
  line-height: 1.8;
}

/* Footer */
.footer {
  background-color: #1f2937;
  color: #ffffff;
  text-align: center;
  padding: 1.5rem 1rem;
  font-size: 0.9rem;
}

.footer a {
  color: #60a5fa;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #9ca3af;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header h1 {
    font-size: 2rem;
  }

  .content h2 {
    font-size: 1.8rem;
  }

  .content h3 {
    font-size: 1.3rem;
  }

  .navbar ul {
    flex-direction: column;
    align-items: center;
  }

  .navbar li {
    margin: 0.5rem 0;
  }
}/* Container für Bild und Text */
.content-wrapper {
  display: flex;
  align-items: flex-start; /* Text und Bild oben ausrichten */
  gap: 1.5rem; /* Abstand zwischen Text und Bild */
}

/* Textbereich links */
.text {
  flex: 1; /* Text nimmt so viel Platz wie nötig ein */
}

/* Bildbereich rechts */
.image-container {
  display: flex;
  flex-direction: column; /* Stapelt Bild und Bildunterschrift */
  align-items: center; /* Zentriert das Bild und die Bildunterschrift */
  max-width: 50%; /* Bildbereich auf 50% des Containers begrenzen */
}
.image-container-100 {
  display: flex;
  flex-direction: column; /* Stapelt Bild und Bildunterschrift */
  align-items: center; /* Zentriert das Bild und die Bildunterschrift */
  max-width: 100%; /* Bildbereich auf 50% des Containers begrenzen */
}

/* Stil für das Bild */
.image-container img {
  max-width: 100%; /* Bild passt sich an den Container an */
  height: auto; /* Seitenverhältnis bleibt erhalten */
  border-radius: 8px; /* Optional: abgerundete Ecken */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Schatten */
}


.image-container-60 img {
  max-width: 100%; /* Bild passt sich an den Container an */
  height: auto; /* Seitenverhältnis bleibt erhalten */
  border-radius: 8px; /* Optional: abgerundete Ecken */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Schatten */
}

/* Bildunterschrift unter dem Bild */
.image-caption {
  font-size: 0.9rem; /* Schriftgröße der Bildunterschrift */
  color: #6b7280; /* Grauer Text */
  text-align: center; /* Zentriert die Bildunterschrift */
  margin-top: 0.5rem; /* Abstand zwischen Bild und Bildunterschrift */
}

.image-caption a {
  color: #60a5fa; /* Linkfarbe */
  text-decoration: none; /* Entfernt die Unterstreichung */
}

.image-caption a:hover {
  text-decoration: underline; /* Unterstreichung beim Hover */
}

.rotated-image {
  max-width: 100%; /* Bild passt sich an den Container an */
  height: auto; /* Seitenverhältnis beibehalten */
  border-radius: 8px; /* Optional: abgerundete Ecken */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Optional: Schatten */
  transform: rotate(90deg); /* Bild um 90 Grad im Uhrzeigersinn drehen */
  object-fit: cover; /* Bildinhalt skalieren, falls nötig */
}

/* Allgemeine Stile für das Video */
.video-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.video-container iframe {
  width: 100%;
  height: 700px;
}

/* Stil für den roten Knopf */
.toggle-button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

/* Knopf hover Effekt */
.toggle-button:hover {
  background-color: darkred;
}

/* Wenn das Video sichtbar ist, wird es ausgeklappt */
.video-container.open {
    max-height: 100%;
}

.footer ul {
  list-style-type: none;
  padding-left: 0; /* Entfernt den linken Abstand */
}

/* Sicherstellen, dass html und body die volle Höhe einnehmen */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Flexbox-Layout für die App */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Hauptinhalt nimmt den verfügbaren Platz ein */
main {
  flex: 1;
}

/* Footer bleibt am unteren Rand */
.footer {
  background-color: #1f2937;
  color: #ffffff;
  text-align: center;
  padding: 1.5rem 1rem;
  font-size: 0.9rem;
  margin-top: auto; /* Stößt den Footer an das Ende des flex-Containers */
}